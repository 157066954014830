import "./orders.css";
import {useContext, useEffect, useState} from "react";
import {Context} from "../../context/Context";
import axios from "axios";
import Order from "./Order";
import {url} from "../../Client"

export default function Orders({wrap}) {
    const [orders, setOrders] = useState([]);
    const {user, dispatch} = useContext(Context);

    useEffect(async () => {
        const res = await axios.get(url + "orders");
        setOrders(res.data.reverse());
        dispatch({type: "UPDATE"})
    }, []);


    return (<div>
            <div className="list-orders" style={{
                flexWrap: wrap ? 'wrap' : '',
                justifyContent: wrap ? 'center' : '',
                overflow: 'scroll',
                alignItems: 'flex-start',
                paddingLeft: wrap ? 0 : 20
            }}>
                {user && orders.map((order, i) => (<div key={i}>
                        {order.status !== 'Собирается' && (user.username === 'admin' || user.username === order.username) &&
                            <Order order={order}/>}
                    </div>))}
            </div>
        </div>)
}
